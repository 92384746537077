import React from "react";
import {
  Box,
  Button,
  Typography,
  makeStyles,
  CircularProgress,
  Grid,
} from "@material-ui/core";
import theme from "../../../utils/theme";
import { useCardDetails } from "./hook";
import { FormFooter } from "../../atoms/FormFooter";

const useStyles = makeStyles((theme) => ({
  form: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: "6px",
    width: "95%",
    padding: "14px",
    height: "fit-content",
    marginTop: "28px",
  },
  element: {
    height: "55px",
    marginTop: "12px",
    fontWeight: 500,
  },
  button: {
    textTransform: "none",
    width: "100%",
    borderRadius: "6px",
  },
  apiView: {
    background: theme.palette.grey[100],
    padding: "14px 5px 0px 14px",
    marginTop: "28px",
    maxHeight: "inherit",
  },
  formHighlight: {
    border: `1px solid ${theme.palette.grey[300]}`,
    width: "95%",
    padding: "14px",
    height: "fit-content",
    marginTop: "28px",
    borderRadius: "6px",
    animation: "$pulse-black 1500ms infinite",
    "&:hover": {
      animation: "none",
    },
  },
  buttonHighlight: {
    textTransform: "none",
    width: "95.5%",
    marginLeft: "10px",

    borderRadius: "6px",
    marginTop: "12px",
    padding: "5px",

    animation: "$pulse-black 1500ms infinite",
  },

  "@keyframes pulse-black": {
    "0%": {
      boxShadow: "0px 0px 2px 4px #4169e1",
    },

    "50%": {
      boxShadow: "0px 0px 0px 0px white",
    },

    "100%": {
      boxShadow: "0px 0px 2px 4px #4169e1",
    },
  },

  "@keyframes bounce": {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(4px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
}));

export const CardDetailsStep = ({
  activeStep,
  accessToken,
  setTokens,
  tokens,
  handleNext,
  response,
  setResponse,
}) => {
  const {
    collectLoading,
    tabIndex,
    collectError,
    tabs,
    handleGenerate,
    isButtonDisabled,
    setTabIndex,
    handleSubmitCardDetails,
    nameRef,
    insuranceRef,
    reasonRef,
    addressRef,
    stateRef,
    phoneRef,
    genderRef,
    dobRef,
    emailRef,
  } = useCardDetails(
    activeStep,
    accessToken,
    setTokens,
    tokens,
    handleNext,
    response,
    setResponse
  );

  const classes = useStyles();
  return (
    <Box display="flex">
      <Box
        className={
          activeStep === 0 && !collectLoading
            ? classes.formHighlight
            : classes.form
        }
      >
        <Box>
          <Typography variant="caption" color="textSecondary">
            Patient Name
          </Typography>
        </Box>

        <Box
          key={"name"}
          style={{
            height: "55px",
            marginTop: "12px",
          }}
          ref={nameRef}
          id="name"
        />

        <Box
          style={{
            backgroundColor: "#F5F8FA",
            padding: "14px",
            borderRadius: "4px",
          }}
        >
          <Box>
            <Typography variant="caption" color="textSecondary"></Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" color="textSecondary">
                Street Address
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: theme.palette.primary.main,
                  cursor: "pointer",
                }}
                onClick={
                  activeStep === 0 && !collectLoading ? handleGenerate : null
                }
              >
                Generate Patient data
              </Typography>
            </Box>

            <Box
              key={"address"}
              disabled={false}
              className={classes.element}
              ref={addressRef}
              id="address"
            />
            <Typography variant="caption" color="textSecondary">
              State
            </Typography>
            <Box
              key={"state"}
              disabled={false}
              className={classes.element}
              ref={stateRef}
              id="state"
            />
          </Box>

          <Box marginTop="15px">
            <Typography variant="caption" color="textSecondary"></Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="caption" color="textSecondary">
                Reason for visit
              </Typography>
            </Box>

            <Box
              key={"reason"}
              disabled={false}
              className={classes.element}
              ref={reasonRef}
              id="reason"
            />
          </Box>

          <Box marginTop="15px" display="flex">
            <Grid container spacing={1}>
              <Grid container item spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="caption" color="textSecondary">
                    Date of Birth
                  </Typography>
                  <Box
                    key={"month"}
                    disabled={false}
                    className={classes.element}
                    ref={dobRef}
                    id="month"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" color="textSecondary">
                    Insurance Policy Number
                  </Typography>
                  <Box>
                    <Box
                      key={"insurance"}
                      disabled={false}
                      className={classes.element}
                      ref={insuranceRef}
                      id="insurance"
                    />
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" color="textSecondary">
                    Gender
                  </Typography>
                  <Box
                    key={"gender"}
                    disabled={false}
                    className={classes.element}
                    ref={genderRef}
                    id="gender"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          <Box marginTop="15px" display="flex">
            <Grid container spacing={1}>
              <Grid container item spacing={3}>
                <Grid item xs={4}>
                  <Typography variant="caption" color="textSecondary">
                    Phone number
                  </Typography>
                  <Box
                    key={"phone"}
                    className={classes.element}
                    ref={phoneRef}
                    id="phone"
                  />
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="caption" color="textSecondary">
                    Email Address
                  </Typography>

                  <Box
                    key={"email"}
                    className={classes.element}
                    ref={emailRef}
                    id="email"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          className={
            !(isButtonDisabled() || collectLoading)
              ? classes.buttonHighlight
              : classes
          }
          marginTop="12px"
        >
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={isButtonDisabled() || collectLoading ? true : false}
            startIcon={
              collectLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : undefined
            }
            onClick={handleSubmitCardDetails}
          >
            {collectLoading ? "Registering" : "Register"}
          </Button>
        </Box>
        {collectError && (
          <Typography
            variant="overline"
            color="error"
            style={{ textTransform: "none" }}
          >
            Something went wrong while submitting data.
          </Typography>
        )}
        <FormFooter collectError={collectError} />
      </Box>
    </Box>
  );
};
