export const TABLE_NAME = "phi";

export const FORM_FIELDS = {
  name: "name",
  phone_number: "phone_number",
  dob: "dob",
  insurance: "insurance_policy_number",
  reason: "reason",
  address: "address",
  state: "state",
  email: "email",
  gender: "gender"
};

export const QUERY_FIELDS = [
  "skyflow_id",
  "name",
  "dob",
  "phone_number",
  "insurance_policy_number",
  "gender",
  "email",
  "reason",
  "state"
];

export const GENDER_REGEX=/(UNSPECIFIED|MALE|FEMALE|NON_BINARY|TRANSGENDER_MALE|TRANSGENDER_FEMALE|OTHER|NON_DISCLOSE)/;
export const DOB_REGEX=/^\d{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/;
export const PHONE_NUMBER_REGEX=/^$|^[+]?[0-9]{0,3}\s*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;
export const EMAIL_REGEX=/^$|^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,})$/;


