import React from "react";
import {
  Box,
  Typography,
  Divider,
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  StepContent,
} from "@material-ui/core";
import logo from "../../../../public/assets/skyflow-logo.svg";
import scroll from "../../../../public/assets/scroll.svg";
import theme from "../../../utils/theme";
import { useStepperForm } from "./hook";
import VaultTable from "../VaultTable";
import { CardDetailsStep } from "../CardDetailsStep";

const useStyles = makeStyles((theme) => ({
  horizontalDivider: {
    background: theme.palette.grey[300],
  },

  stepperRoot: {
    padding: "0px",
  },

  stepContent: {
    marginTop: "0px",
    marginLeft: "11px",
    borderLeft: "3px solid #cbd6e2",
  },

  activeStepContent: {
    marginTop: "0px",
    marginLeft: "11px",
    borderLeft: "3px solid blue",
  },

  connectorLine: {
    borderLeft: "3px solid green",
    height: "100%",
  },
  connectorVertical: {
    padding: "0px",
  },
  connectorLineVertical: {
    minHeight: "0px",
  },
  connectorActive: {
    borderLeft: "3px solid black",
  },
  scroll: {
    animation: "$bounce 1.6s linear infinite",
  },
  "@keyframes bounce": {
    "0%": {
      transform: "translateY(0)",
    },
    "50%": {
      transform: "translateY(4px)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
}));

export const StepperForm = () => {
  const classes = useStyles();

  const {
    activeStep,
    handleNext,
    setResponse,
    rows,
    error,
    loading,
    tokens,
    insertedRow,
    response,
    tokenizeError,
    tokensLoading,
    accessToken,
    setTokens,
    paymentResponse,
    setPaymentResponse,
  } = useStepperForm();
  const step2 = React.createRef();
  const step3 = React.createRef();
  const [tableTab, setTableTab] = React.useState(0);

  React.useEffect(() => {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };
  }, []);
  return (
    <Box
      display="block"
      width="1080px"
      marginTop="12px"
      justifyContent="space-around"
    >
      <Box width="100%">
        <Box display="flex">
          <Box width="100%">
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={
                <StepConnector
                  active={true}
                  classes={{
                    line: classes.connectorLine,
                    vertical: classes.connectorVertical,
                    lineVertical: classes.connectorLineVertical,
                    active: classes.connectorActive,
                  }}
                />
              }
              classes={{
                root: classes.stepperRoot,
              }}
            >
              <Step expanded={true} key={0}>
                <StepLabel>
                  <Typography
                    variant="body1"
                    style={{
                      fontWeight: "bold",
                      paddingLeft: "25px",
                    }}
                  >
                    Securely collect sensitive data
                  </Typography>
                </StepLabel>
                <StepContent
                  classes={{
                    root:
                      activeStep >= 0
                        ? classes.activeStepContent
                        : classes.stepContent,
                  }}
                >
                  <Box
                    style={{
                      paddingLeft: "25px",
                    }}
                  >
                    <Typography variant="body2" style={{ marginTop: "4px" }}>
                      Fill out the form below with fake data and hit submit!
                    </Typography>
                    <CardDetailsStep
                      activeStep={activeStep}
                      setResponse={setResponse}
                      accessToken={accessToken}
                      setTokens={setTokens}
                      handleNext={handleNext}
                      tokens={tokens}
                      response={response}
                    />
                  </Box>
                  <Divider
                    style={{
                      margin: "30px 0px 30px 25px",
                    }}
                    classes={{ root: classes.horizontalDivider }}
                    variant="middle"
                  />
                  <Box
                    className={classes.scroll}
                    display={
                      activeStep === 1 && tableTab === 0 ? "flex" : "none"
                    }
                    justifyContent="center"
                    marginTop="-50px"
                  >
                    <img
                      src={scroll}
                      onClick={() =>
                        window.scrollTo(0, step2.current.offsetTop)
                      }
                      style={{ height: "40px", width: "40px" }}
                    />
                  </Box>
                </StepContent>
              </Step>

              <Step expanded={true} key={1} ref={step2}>
                <StepLabel>
                  <Typography
                    variant="body1"
                    style={{
                      marginTop: "4px",
                      fontWeight: "bold",
                      paddingLeft: "25px",
                    }}
                  >
                    Protect sensitive data in a Vault
                  </Typography>
                </StepLabel>
                <StepContent>
                  <Box style={{ paddingLeft: "25px" }}>
                    <Typography
                      variant="body2"
                      style={{
                        marginTop: "4px",
                        marginBottom: "4px",
                      }}
                    >
                      The original data resides in Skyflow's secure,
                      PCI-compliant environment. Your database just has to store
                      non-sensitive tokens, reducing your PCI-scope.
                    </Typography>
                    <VaultTable
                      rows={rows}
                      error={error}
                      handleNext={handleNext}
                      activeStep={activeStep}
                      loading={loading}
                      tokenRows={tokens}
                      insertedRow={insertedRow}
                      response={response}
                      tokenizeError={tokenizeError}
                      tokensLoading={tokensLoading}
                      tableTab={tableTab}
                      setTableTab={setTableTab}
                    />
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
