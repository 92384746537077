import React from "react";
import { bulkGetRecords, getRecord, getTokenRecords } from "../../../services";
import { getBearerToken } from "../../../utils/helper";
import { TABLE_NAME, QUERY_FIELDS } from "../../../utils/constants";

export const useStepperForm = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [tokenizeError, setTokenizeError] = React.useState(false);
  const [accessToken, setAccessToken] = React.useState();
  const [rows, setRows] = React.useState();
  const [response, setResponse] = React.useState({});
  const [error, setError] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [tokens, setTokens] = React.useState();
  const [insertedRow, setInsertedRow] = React.useState();
  const [tokensLoading, setTokensLoading] = React.useState(false);
  const [rowLength, setRowLength] = React.useState();
  const [paymentResponse, setPaymentResponse] = React.useState({});
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const getRecords = () => {
    setLoading(true);
    bulkGetRecords(
      TABLE_NAME,
      {
        limit: "25",
        redaction: "PLAIN_TEXT",
        fields: QUERY_FIELDS,
      },
      accessToken,
      async (data) => {
        if (data.records) {
          setError(false);
          setRowLength(data.records.length);
          setRows(data.records);
        } else {
          setError(true);
        }
        setLoading(false);
      }
    );
  };
  const getallrecords = async () => {
    setTokensLoading(true);
    const skyflow_ids = rows.map((row) => {
      return row.fields.skyflow_id;
    });
    getTokenRecords(skyflow_ids, async (data) => {
      if (data.records) {
        setTokens(data.records);

        setTokenizeError(false);
        setTokensLoading(false);
      } else {
        setTokenizeError(true);
      }
      setTokensLoading(false);
    });
  };

  React.useEffect(() => {
    !accessToken &&
      getBearerToken().then((res) => {
        if (res) {
          setAccessToken(res);
        } else {
          setError(true);
        }
      });
  }, []);

  React.useEffect(() => {
    accessToken && getRecords();
  }, [accessToken]);

  React.useEffect(() => {
    rows && rows.length === rowLength && getallrecords();
  }, [rows]);

  React.useEffect(() => {
    response &&
      response.records &&
      getRecord(
        TABLE_NAME,
        response.records[0].fields.skyflow_id,
        {
          redaction: "PLAIN_TEXT",
          fields: QUERY_FIELDS,
        },
        accessToken,
        (tokenData) => {
          if (!tokenData.fields) {
            setError(true);
          } else {
            setError(false);
            setInsertedRow(tokenData);
            rows ? setRows([tokenData, ...rows]) : setRows([tokenData]);
          }
        }
      );

    response &&
      response.records &&
      setTokens(
        tokens ? [response.records[0], ...tokens] : [response.records[0]]
      );
  }, [response]);

  return {
    activeStep,
    handleNext,
    setResponse,
    rows,
    loading,
    tokensLoading,
    tokens,
    insertedRow,
    response,
    tokenizeError,
    tokensLoading,
    error,
    accessToken,
    setTokens,
    paymentResponse,
    setPaymentResponse,
  };
};
