import React from "react";
import theme from "../../../utils/theme";
import { deleteRecord, getRecord, insertTokenRecord } from "../../../services";
import { useSkyflow } from "../../../utils/skyflowjs";
import mockdata from "../../../utils/mockData/mockData.json";
import Skyflow from "skyflow-js";
import {
  TABLE_NAME,
  FORM_FIELDS,
  QUERY_FIELDS,
  GENDER_REGEX,
  DOB_REGEX,
  PHONE_NUMBER_REGEX,
  EMAIL_REGEX,
} from "../../../utils/constants";
import properties from "../../../utils/properties";

export const useCardDetails = (
  activeStep,
  accessToken,
  setTokens,
  tokens,
  handleNext,
  response,
  setResponse
) => {
  const options = {
    inputStyles: {
      base: {
        border: `1px solid ${theme.palette.grey[300]}`,
        padding: "10px 16px",
        "border-radius": "4px",
        color: theme.palette.text.primary,
        backgroundColor: "#EAF0F6",
      },
      complete: {
        color: theme.palette.text.primary,
      },
      empty: {},
      focus: {},
      invalid: {
        border: "1px solid #f44336",
      },
    },
    labelStyles: {
      styles: {
        base: {
          color: theme.palette.text.secondary,
          "font-family":
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
          fontWeight: 500,
          fontSize: "12px",
        },
      },
    },
    errorTextStyles: {
      base: {
        fontSize: "14px",
        "font-family": "Roboto, sans-serif",
      },
    },
  };

  const nameRef = React.createRef();
  const insuranceRef = React.createRef();
  const reasonRef = React.createRef();
  const phoneRef = React.createRef();
  const genderRef = React.createRef();
  const dobRef = React.createRef();
  const emailRef = React.createRef();
  const addressRef = React.createRef();
  const stateRef = React.createRef();

  const [container, setContainer] = React.useState();
  const [cvvContainer, setCVVContainer] = React.useState();

  const [nameField, setName] = React.useState("");
  const [error, setError] = React.useState(false);
  const [phoneNum, setPhoneNum] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [insurance, setInsurance] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [state, setState] = React.useState("");

  const skyflow = useSkyflow();
  const [collectLoading, setCollectLoading] = React.useState(false);
  const [tempResponse, setTempResponse] = React.useState({});
  const [tabIndex, setTabIndex] = React.useState(0);
  const [collectError, setCollectError] = React.useState(false);
  const [elementState, setElementState] = React.useState({
    nameElement: null,
    phoneElement: null,
    reasonElement: null,
    genderElement: null,
    dobElement: null,
    emailElement: null,
    insuranceElement: null,
    addressElement: null,
    stateElement: null,
  });
  const [elementValidState, setElementValidState] = React.useState({
    nameElement: true,
    phoneElement: true,
    reasonElement: true,
    genderElement: true,
    dobElement: true,
    emailElement: true,
    insuranceElement: true,
    addressElement: true,
    stateElement: true,
  });

  React.useEffect(() => {
    const container = skyflow.container("COLLECT");
    setContainer(container);
    const cvvContainer = skyflow.container("COLLECT");
    setCVVContainer(cvvContainer);
    const name = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.name,
      ...options,
      inputStyles: {
        base: {
          border: `1px solid ${theme.palette.grey[300]}`,
          padding: "10px 16px",
          "border-radius": "4px",
          color: theme.palette.text.primary,
        },
        complete: {
          color: theme.palette.text.primary,
        },
        empty: {},
        focus: {},
        invalid: {
          border: "1px solid #f44336",
        },
      },
      type: Skyflow.ElementType.CARDHOLDER_NAME,
      placeholder: "Eg: Sherlock Holmes",
    });

    const gender = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.gender,
      ...options,
      validations: [
        {
          type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
          params: {
            regex: GENDER_REGEX,
            error: "Invalid input",
          },
        },
      ],
      type: Skyflow.ElementType.INPUT_FIELD,
      placeholder: "Eg: Unspecified",
    });

    const dob = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.dob,
      ...options,
      validations: [
        {
          type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
          params: {
            regex: DOB_REGEX,
            error: "Invalid DOB",
          },
        },
      ],
      type: Skyflow.ElementType.INPUT_FIELD,
      placeholder: "Eg: 13/09/1980",
    });

    const phoneNum = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.phone_number,
      ...options,
      disabled: false,
      placeholder: "2255",
      validations: [
        {
          type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
          params: {
            regex: PHONE_NUMBER_REGEX,
            error: "Invalid phone number",
          },
        },
      ],
      type: Skyflow.ElementType.INPUT_FIELD,
    });

    const reason = container.create(
      {
        table: TABLE_NAME,
        column: FORM_FIELDS.reason,
        ...options,
        disabled: false,
        placeholder: "High fever",
        type: Skyflow.ElementType.INPUT_FIELD,
      },
      { required: true }
    );

    const email = container.create({
      table: TABLE_NAME,
      column: FORM_FIELDS.email,
      ...options,
      disabled: false,
      validations: [
        {
          type: Skyflow.ValidationRuleType.REGEX_MATCH_RULE,
          params: {
            regex: EMAIL_REGEX,
            error: "Invalid email",
          },
        },
      ],
      placeholder: "sherlock.holmes@gmail.com",
      type: Skyflow.ElementType.INPUT_FIELD,
    });

    const insurance = container.create(
      {
        table: TABLE_NAME,
        column: FORM_FIELDS.insurance,
        ...options,
        disabled: false,

        placeholder: "Eg. HCG2396494",
        type: Skyflow.ElementType.INPUT_FIELD,
      },
      { required: true }
    );

    const address = container.create(
      {
        table: TABLE_NAME,
        column: FORM_FIELDS.address,
        ...options,
        disabled: false,
        placeholder: "221c Baker St, London NW1 6XE, United Kingdom",
        type: Skyflow.ElementType.INPUT_FIELD,
      },
      { required: true }
    );

    const state = container.create(
      {
        table: TABLE_NAME,
        column: FORM_FIELDS.state,
        ...options,
        disabled: false,
        placeholder: "Arizona",
        type: Skyflow.ElementType.INPUT_FIELD,
      },
      { required: true }
    );

    setElementState({
      nameElement: name,
      phoneElement: phoneNum,
      reasonElement: reason,
      genderElement: gender,
      dobElement: dob,
      emailElement: email,
      insuranceElement: insurance,
      addressElement: address,
      stateElement: state,
    });

    if (nameRef) {
      name.mount(nameRef.current);
      phoneNum.mount(phoneRef.current);
      insurance.mount(insuranceRef.current);
      reason.mount(reasonRef.current);
      gender.mount(genderRef.current);
      dob.mount(dobRef.current);
      email.mount(emailRef.current);
      address.mount(addressRef.current);
      state.mount(stateRef.current);

      name.on("CHANGE", (data) => {
        setName(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, nameElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, nameElement: false };
          });
        }
      });

      phoneNum.on("CHANGE", (data) => {
        setPhoneNum(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, phoneElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, phoneElement: false };
          });
        }
      });

      reason.on("CHANGE", (data) => {
        setReason(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, reasonElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, reasonElement: false };
          });
        }
      });

      gender.on("CHANGE", (data) => {
        setGender(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, genderElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, genderElement: false };
          });
        }
      });

      dob.on("CHANGE", (data) => {
        setDob(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, dobElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, dobElement: false };
          });
        }
      });

      email.on("CHANGE", (data) => {
        setEmail(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, emailElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, emailElement: false };
          });
        }
      });

      insurance.on("CHANGE", (data) => {
        setInsurance(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, insuranceElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, insuranceElement: false };
          });
        }
      });

      address.on("CHANGE", (data) => {
        setAddress(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, addressElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, addressElement: false };
          });
        }
      });

      state.on("CHANGE", (data) => {
        setState(data.value);
        if (data.isEmpty || !data.isValid) {
          setElementValidState((prevState) => {
            return { ...prevState, stateElement: true };
          });
        } else {
          setElementValidState((prevState) => {
            return { ...prevState, stateElement: false };
          });
        }
      });
    }
  }, []);

  React.useEffect(() => {
    response &&
      response.records &&
      getRecord(
        TABLE_NAME,
        response.records[0].fields.skyflow_id,
        {
          redaction: "PLAIN_TEXT",
          fields: QUERY_FIELDS,
        },
        accessToken,
        (tokenData) => {
          if (!tokenData.fields) {
            setError(true);
          } else {
            setError(false);
            setInsertedRow(tokenData);
            rows ? setRows([tokenData, ...rows]) : setRows([tokenData]);
          }
        }
      );

    response &&
      response.records &&
      setTokens(
        tokens ? [response.records[0], ...tokens] : [response.records[0]]
      );
  }, [response]);

  React.useEffect(() => {
    tempResponse &&
      tempResponse.records &&
      insertTokenRecord(tempResponse.records[0].fields)
        .then(() => {
          setResponse(tempResponse);
          setCollectLoading(false);

          setTabIndex(1);
          handleNext();
        })
        .catch((e) => {
          deleteRecord(
            TABLE_NAME,
            tempResponse.records[0].fields.skyflow_id,
            accessToken,
            (data) => {
              setCollectError(true);
              setCollectLoading(false);
            }
          );
        });
  }, [tempResponse]);

  const request = `curl -i -X POST \\
  ${properties.VAULT_URL}
  /v1/vaults/${properties.VAULT_ID}/${TABLE_NAME} \\
  -H 'Authorization: YOUR_API_KEY_HERE' \\
  -H 'Content-Type: application/json' \\
  -d '{
    "records": [
      {
        "fields": {
          "name": "${nameField}",
          "phone_number": "${phoneNum}",
          "address": "${address}",
          "state": "${state}",
          "email": "${email}",
          "insurance_policy_number": "${insurance}",
          "reason": "${reason}",
          "dob": "${dob}",
          "gender": "${gender}",
        }
      }
    ]
  }'`;

  const tabs = [
    {
      title: "Request",
      content: request,
    },
    { title: "Response", content: JSON.stringify(response, undefined, 2) },
  ];

  const handleGenerate = () => {
    setCollectError(false);
    const mockIndex = Math.round(Math.random() * 1000);
    elementState.addressElement.setValue(mockdata[mockIndex].address);
    elementState.stateElement.setValue(mockdata[mockIndex].state);
    elementState.phoneElement.setValue(mockdata[mockIndex].phone_number);
    elementState.genderElement.setValue(mockdata[mockIndex].gender);
    elementState.reasonElement.setValue(mockdata[mockIndex].reason);
    elementState.dobElement.setValue(mockdata[mockIndex].dob);
    elementState.emailElement.setValue(mockdata[mockIndex].email);
    elementState.nameElement.setValue(mockdata[mockIndex].name);
    elementState.insuranceElement.setValue(mockdata[mockIndex].insurance);
  };

  const handleSubmitCardDetails = () => {
    setCollectLoading(true);
    try {
      container
        .collect()
        .then((res) => {
          setTempResponse(res);
          setCollectError(false);
          /*setResponse(res);
          setCollectLoading(false);

          setTabIndex(1);
          handleNext();*/
        })
        .catch((e) => {
          setCollectLoading(false);
          setCollectError(true);
        });
    } catch (e) {
      setCollectLoading(false);
      setCollectError(true);
    }
  };
  const isButtonDisabled = () => {
    let disabled = true;
    const invalidInputs = Object.keys(elementValidState).filter((x) => {
      return elementValidState[x];
    });
    disabled = invalidInputs.length ? true : false;
    return disabled || (response && response.records ? true : false);
  };

  return {
    container,
    cvvContainer,
    accessToken,
    collectLoading,
    error,
    response,
    tempResponse,
    tabIndex,
    collectError,
    tabs,
    handleGenerate,
    isButtonDisabled,
    setCollectLoading,
    setCollectError,
    setTabIndex,
    handleSubmitCardDetails,
    nameRef,
    insuranceRef,
    reasonRef,
    addressRef,
    stateRef,
    phoneRef,
    genderRef,
    dobRef,
    emailRef,
  };
};
